import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Player, PlayerSignup, School, SchoolDocument, SchoolSocialConfig } from '../../models/school';
import { AdminSchoolsPaginatedData, User } from '../../models/user';
import { HttpHelperService } from '../http-helper.service';
import { SchoolTotalDueItem } from '../../models/payouts';
import { JournalEntrySale } from '../../models/sales';
import { SchoolTemplateDesignDetail } from '../../models/templates';
import { Model } from '../../models/blank';
import { PaginatedData, PaginatedRequest } from '../../models/utils';


export type SchoolAthletes = {
  id: number;
  fullName: string;
  commissionsUserName: string;
  accountManagerName: string;
  totalAthletes: number;
  newThisWeek: number;
  newThisMonth: number;
}

@Injectable({
  providedIn: 'root'
})
export class SchoolService {
  allSchoolList$: School[] = null;
  private endpoint: string = 'schools';

  constructor(private httpHelper: HttpHelperService) { }

  resetState() {
    this.allSchoolList$ = null;
  }

  getAll(): Observable<School[]> {
    return this.httpHelper.request<School[]>(this.endpoint);
  }

  get(id: number): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${id}`);
  }

  filter(item: School): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}`, { params: item, method: 'GET' });
  }

  create(item: School): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}`, { body: item, method: 'POST' });
  }

  edit(item: School): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${item.id}`, { body: item, method: 'PUT' });
  }

  partialEdit(item: School): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${item.id}`, { body: item, method: 'PATCH' });
  }

  updateBuildAts(ids: number[]): Observable<void> {
    return this.httpHelper.request<void>(`${this.endpoint}/update_build_ats`, { body: { ids }, method: 'POST' });
  }

  documents(item: School): Observable<SchoolDocument[]> {
    return this.httpHelper.request<SchoolDocument[]>(`${this.endpoint}/${item.id}/documents/`);
  }

  importPlayers(item: any, school: School): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${school.id}/user_creation`, { body: item, method: 'POST' });
  }

  sendAllVerificationEmails(schoolId: number): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${schoolId}/send_verification_emails`);
  }

  deleteSchool(schoolId: number): Observable<School> {
    return this.httpHelper.request<School>(`${this.endpoint}/${schoolId}`, { method: 'DELETE' });
  }

  createSocialAccount(schoolId: number, subdomain: string): Observable<SchoolSocialConfig> {
    return this.httpHelper.request<SchoolSocialConfig>(`${this.endpoint}/${schoolId}/create_social_account`, { method: 'POST', body: { subdomain } });
  }

  totalDue(): Observable<SchoolTotalDueItem[]> {
    return this.httpHelper.request<SchoolTotalDueItem[]>(`${this.endpoint}/total_due`);
  }

  preNewPayout(schoolId: number): Observable<{ school: School, journalEntries: JournalEntrySale[] }> {
    return this.httpHelper.request<{ school: School, journalEntries: JournalEntrySale[] }>(`${this.endpoint}/${schoolId}/prep_new_payout`);
  }

  journalEntires(schoolId: number, startDate: Date, endDate: Date): Observable<JournalEntrySale[]> {
    let search = new HttpParams({
      fromObject: {
        'start_date': startDate.toISOString().split('T')[0],
        'end_date': endDate.toISOString().split('T')[0]
      }
    })
    return this.httpHelper.request<JournalEntrySale[]>(`${this.endpoint}/${schoolId}/journal_entries`, { method: "GET", search: search });
  }

  allDesigns(schoolId: number): Observable<SchoolTemplateDesignDetail[]> {
    return this.httpHelper.request<SchoolTemplateDesignDetail[]>(`${this.endpoint}/${schoolId}/all_designs`);
  }

  signupLink(schoolId: number, baseRoute: string): Observable<string> {
    return this.httpHelper.request<string>(`${this.endpoint}/${schoolId}/signup_link`, {body: {baseRoute}, method: "POST"});
  }

  loadSignupForm(token: string): Observable<School> {
    return this.httpHelper.request<School>(`signup_form`, { body: { token }, method: "POST" });
  }

  loadOptOutForm(token: string): Observable<School> {
    return this.httpHelper.request<School>(`opt_out_form`, { body: { token }, method: "POST" });
  }

  productSheetViews(id: number): Observable<any> {
    return this.httpHelper.request<any>(`${this.endpoint}/${id}/product_sheet_view`, { method: "GET" });
  }

  playerSignup(player: PlayerSignup, user: User, schoolId: number): Observable<string> {
    return this.httpHelper.request<string>(`${this.endpoint}/${schoolId}/player_signup`, { body: {player, user}, method: "POST" });
  }

  models(schoolId: number): Observable<Model[]> {
    return this.httpHelper.request<Model[]>(`${this.endpoint}/${schoolId}/models`, { method: "GET" });
  }

  signUpList(request: PaginatedRequest<School & {user?: number}>): Observable<PaginatedData<SchoolAthletes>> {
    return this.httpHelper.request<PaginatedData<SchoolAthletes>>(`${this.endpoint}/sign-ups`, { method: "GET", search: request });
  }

  syncSchoolCollections(schoolId: number): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}/${schoolId}/sync-collections`, { method: "POST" });
  }

  syncSchoolShopifyProducts(schoolId: number): Observable<Player> {
    return this.httpHelper.request<Player>(`${this.endpoint}/${schoolId}/sync-shopify-products`, { method: "POST" });
  }

}
