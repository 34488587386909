import { Blank, BlankModel, Model } from './blank';
import { PandaTemplate, PandaDocument } from './document';
import { SchoolTemplateDesign, SchoolTemplateDesignFull, Templates } from "./templates";
import { User } from './user';

export const playerYears = ['FR', 'SO', 'JR', 'SR', 'RSFR', 'RSSO', 'RSJR', 'RSSR'] as const;
export type PlayerYear = typeof playerYears[number];

export const licenseTypes = ['Pandadoc', 'Optout'] as const;
export type LicenseType = typeof licenseTypes[number];

export const schoolTypes = ['COLLEGE', 'HIGHSCHOOL', 'CLUBSPORTS', 'CORPORATE'] as const;
export type SchoolType = typeof schoolTypes[number];

export interface School {
  id?: number;
  mainLogo?: string;
  status?: string;

  integrationId?: string;
  integrationType?: string;
  fullName?: string;
  fullType?: string;
  abbrName?: string;
  abbrType?: string;
  nickname?: string;
  nicknameType?: string;
  letters?: string;
  lettersType?: string;
  mascot?: string;
  mascotType?: string;
  chant?: string;
  chantType?: string;
  location?: string;
  established?: string;
  conference?: string;
  primaryColor?: string;
  primaryHex?: string;
  primaryCode?: string;
  primaryInk?: string;
  primaryThread?: string;
  secondaryColor?: string;
  secondaryHex?: string;
  secondaryCode?: string;
  secondaryInk?: string;
  secondaryThread?: string;
  tertiaryColor?: string;
  tertiaryHex?: string;
  tertiaryCode?: string;
  tertiaryInk?: string;
  standardCode?: string;
  noPayouts?: boolean;
  playerDesignsAllowed?: boolean;
  nilPlusEnabled?: boolean;
  defaultLicenseType?: LicenseType;
  playerRequiredTemplates?: number[];
  defaultPlayerLicense?: number;
  brandSheet?: string;
  playerSignups?: boolean;
  socialEnabled?: boolean;
  socialAccountId?: string;
  socialSubdomain?: string;
  activeSocialAccountId?: string;
  activeSocialSubdomain?: string;
  productGroup?: number;
  quickAddDesigns?: boolean;
  rewardsType?: number;
  commissionsUser?: number;
  accountManager?: number;
  commissionsRate?: number;
  reportingEntities?: number[];
  manufacturers?: number[];
  achSubmitted?: boolean;

  teams?: SchoolSport[];
  blanks?: SchoolBlank[];
  files?: SchoolFile[];
  primaryColorGroup?: string;
  secondaryColorGroup?: string;
  tertiaryColorGroup?: string;
  organization?: number;
  blankSelectionLimit?: number;
  players?: Player[];
  allPlayers?: Player[];
  buildsNeeded?: Player[];
  buildsNotNeeded?: Player[];
  needsBuild?: boolean;
  featured?: boolean;
  active?: boolean;
  schoolType?: SchoolType;
  playerSignupEmailId?: string;
  playerStoreUpdateEmailId?: string;
  shopDomain?: string;
  merchantId?: string;
  storeUrl?: string;
  collectionUrl?: string;
  storeHandle?: string;
  shopFooter?: string;
  discount?: number;
  maleSportsHeading?: string;
  femaleSportsHeading?: string;
  sharedSportsHeading?: string;
  createdAt?: Date;
  deletedAt?: Date;
  buildAt?: Date;
  updatedAt?: Date;
  logoCount?: number;
  handleString?: string;
  users?: User[];
  playerBuilds?: number;
}

export interface SchoolLogo {
  id?: number;
  school?: number;
  schoolSport?: number;
  name?: string;
  main?: boolean;
  originalUpload?: string;
  darkColorway?: string;
  darkEmbFilename?: string;
  darkThreadSequence?: string;
  lightColorway?: string;
  lightEmbFilename?: string;
  lightThreadSequence?: string;
  whiteColorway?: string;
  whiteEmbFilename?: string;
  whiteThreadSequence?: string;
  primaryColorway?: string;
  primaryEmbFilename?: string;
  primaryThreadSequence?: string;
  secondaryColorway?: string;
  secondaryEmbFilename?: string;
  secondaryThreadSequence?: string;
  finalLogo?: string;
  pvFile?: string;
  vectorFile?: string;
  embFile?: string;
  aspectRatio?: string;
  embroideryLoaded?: boolean;
  uploadAt?: Date;
  buildAt?: Date;
  active?: boolean;
  embroideryNeeded?: boolean;
  hasEmbTemplate?: boolean;
}

export interface SchoolFont {
  id?: number;
  school?: number;
  name?: string;
  tagValue?: string;
  familyName?: string;
  fontFile?: string;
  internalName?: string;
  active?: boolean;

}

export interface SchoolDocument {
  id?: number;
  school?: number;
  pandaTemplate?: PandaTemplate;
  pandaDocument?: PandaDocument;
  required?: boolean;
}

export interface PlayerDocument {
  id?: number;
  userId?: number;
  pandaTemplate?: PandaTemplate;
  pandaDocument?: PandaDocument;
  required?: boolean;
}

export interface Sport {
  id?: number;
  name?: string;
  icon?: string;
}

export interface SchoolSport {
  id?: number;
  school?: number;
  sport?: Sport;
  nickname?: string;
  players?: Player[];
  totalPlayers?: number;
  gender?: string;
  logo?: string;
  image1?: string;
  image2?: string;
  signupPercent?: number;
  genderDisplayName?: string;
  useNicknameInStore?: boolean;
  useNicknameOnProducts?: boolean;
  noStore?: boolean;
  noRoster?: boolean;
  handleString?: string;
  license?: number;
  requiredPlayerTemplate?: number;
}

export interface SchoolBlank {
  id?: number;
  blank?: BlankModel;
  model?: string;
  color?: string;
  typeCode?: string;
  schoolId?: number;
}

export interface SchoolBlankBasic {
  id?: number;
  blank?: number;
  school?: number;
  model?: string;
  color?: string;
  typeCode?: string;
}

export interface SchoolModelPrice {
  id?: number;
  model?: string;
  school?: number;
  salePrice?: number;
}

export interface ModelWithPrice extends Model {
  id?: number;
  school?: number;
  schoolSalePrice?: number;
}

export type CollectionSort = {
  id?: number;
  school?: number;
  design?: number;
  model?: string;
  sortValue?: number;
  badge?: string;
};


export interface AdminSchoolModelPaginatedData {
  count: number,
  next: number,
  previous: number,
  results: SchoolModelPrice[];
  pageSize?: number;
  currentPage?: number;
};

export type PlayerSignup = {
  firstName: string;
  lastName: string;
  pNumber: string;
  pPosition: string;
  hometown: string;
  highSchool: string;
  pYear: string;
  schoolId: number;
  sportId: number;
}

export const PLAYERYEARS = ["FR", "SO", "JR", "SR", "RSFR", "RSSO", "RSJR", "RSSR"]; 

export interface Player {
  id?: number;
  integrationId?: string;
  integrationType?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  nickname?: string;
  pNumber?: string;
  pPosition?: string;
  hometown?: string;
  highSchool?: string;
  pYear?: string;
  classOf?: number;
  image?: string;
  image1?: string;
  image2?: string;
  createdAt?: Date;
  deletedAt?: Date;
  updateAt?: Date;
  buildAt?: Date;
  license?: number;
  school?: School;
  schoolId?: number;
  sportId?: number;
  sport?: SchoolSport;
  sportName?: string;
  labels?: any;
  storeUrl?: string;
  user?: number;
  email?: string;
  status?: string;
  loginCreatedAt?: Date;
  actionShot?: string;
  headshot?: string;
  featured?: boolean;
  storeGreeting?: string;
  optedOut?: boolean;
  isInternational?: boolean;
  payoutsDisabled?: boolean;
  schoolType?: string;
  licenseType?: LicenseType;
  productCount?: number;
}

export interface SchoolTemplates {
  id?: number;
  approved?: boolean;
  approvedAt?: Date;
  schoolId?: number;
  school?: number,
  template?: any;
  darkColorway?: string;
  lightColorway?: string;
  whiteColorway?: string;
  originalUpload?: string;
  license?: string;
  designs?: SchoolTemplateDesignFull[];
}

export interface SchoolTemplateBlankRejects {
  id?: number;
  template?: Templates,
  blank?: Blank,
  school?: School
}
export interface TemplatePreview {
  schoolTemplate: SchoolTemplates;
  categories?: any;
  templateBlanks: any;
  school: School;
  design?: SchoolTemplateDesign;
}
export interface ViewTemplateBlank {
  schoolTemplate: SchoolTemplates;
  template: Templates;
  product: SchoolBlank;
  isRejected: SchoolTemplateBlankRejects
}

export interface PlayerPaginate {
  count: number,
  next: string,
  previous: any,
  results: Blank[],
  currentPage?: number
}

export interface PlayerDesign {
  id?: number,
  template?: Templates,
  designs?: SchoolTemplateDesignFull[],
  approved?: string,
  school?: School,
  lightColorway?: string,
}

export interface SchoolSocialConfig {
  id?: string;
  name?: string;
  subdomain?: string;
  clientType?: string;
  logo?: string;
  teamName?: string;
}

export interface PlayerVideoData {
  items?: any[];
  totalPages?: number;
  totalItems?: number;
  page?: number;
  size?: number;
  views?: number;
  ctaClicks?: number;
  shares?: number;
}

export type SchoolFile = {
  id?: number;
  file: string;
  school: number;
  fileType: string;
  uploadedAt?: Date;
}
